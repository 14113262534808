import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Link } from 'gatsby'

const AllJobs = ({data}) => {
    return (
        <Layout>
            <div className="flex justify-center items-center flex-col">
            <div className="p-6 m-3 text-lg border-gray-400 text-white w-52 text-center">Title</div>
            
                {data.allContentfulAd.edges.map(edge => 
                    <Link to={`/${edge.node.title}`}>
                      <div className="p-6 m-3 border border-orange w-52 text-center">{edge.node.title}</div>
                    </Link>
                )}
            </div>
        </Layout>
    )
}

export default AllJobs


export const query = graphql`
  {
    allContentfulAd {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`


